import React from 'react';
import {
  AciveBar,
  CircleShadow,
  FcCircle,
  FcValue,
  ProgressBarContainer,
  Value,
} from '../Assessment.style';

const ProgressBar = ({ value, totalValue, isFlashcard = false, isActive }) => {
  let percentage = value ? Math.floor((value * 100) / totalValue) : 0;

  return (
    <>
      <ProgressBarContainer isFlashcard={isFlashcard} isActive={isActive}>
        <AciveBar percentage={percentage} isFlashcard={isFlashcard}>
          {isFlashcard ? (
            <div
              style={{
                position: 'relative',
              }}
            >
              {isActive && <FcValue>{percentage || 0}%</FcValue>}

              <FcCircle isActive={isActive} />
            </div>
          ) : (
            <CircleShadow>
              <Value>{percentage || 0}%</Value>
            </CircleShadow>
          )}
        </AciveBar>
      </ProgressBarContainer>
    </>
  );
};

export default ProgressBar;
