const mixpanel = require("mixpanel-browser");

let env_check =
  process.env.REACT_APP_ENVIORNMENT === "production" ||
  process.env.REACT_APP_ENVIORNMENT === "stage" ||
  process.env.REACT_APP_ENVIORNMENT === "patch";

let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
    }
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (env_check) {
      mixpanel.reset();
    }
  },
};

export let Mixpanel = actions;
