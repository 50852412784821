import { Box, styled } from '@mui/material';
import TypographyCompo from './../Typography/TypographyCompo';
import Palette from '../../../config/palette';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import GRAPHICS from '../../../assets/images/assessment';

export const Container = styled('div')(({ theme, isMyLibrary }) => ({
  marginTop: isMyLibrary ? '20px' : '120px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '20px',
  },
}));

export const StackWrapper = styled('div')(({ theme, open }) => ({
  background: open ? 'transparent' : '#F8F7FC',
  padding: '0px 40px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 0px',
  },

  // marginTop: open && '230px',
  marginBottom: '16px',

  '@media (min-height: 600px) and (max-height: 990px)': {
    marginTop: open && '330px',
  },
}));
export const ColDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    padding: '0 20px',
  },
}));

export const FlashCardInfoTypo = styled(TypographyCompo)(
  ({ theme, isValue, color, active }) => ({
    color: color ? color : active ? '#624BA2' : '#5D5D5D',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: isValue ? '24px' : '16px',
    fontStyle: 'normal',
    fontWeight: isValue ? 700 : 500,
    lineHeight: '180%',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: isValue ? '22px' : '12px',
    },
  })
);

export const DividerWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export const FlipCard = styled('div')(({ theme, flipVertical, open }) => ({
  perspective: '1000px',
  width: '100%',
  // minHeight: open ? '60%' : 'calc(100vh - 150px)',
  minHeight: open ? '675px' : '550px',
  // overflow: 'hidden',
  maxWidth: open ? '1200px' : '800px',
  maxHeight: open ? '675px' : '450px',

  [theme.breakpoints.down('sm')]: {
    // minHeight: open ? '60%' : 'calc(100vh - 150px)',
    minHeight: '47vh',
  },

  '.flipper': {
    transition: '0.5s ease-in-out',
    transformStyle: 'preserve-3d',
    position: 'relative',
    width: '100%',
    height: '100%',
    transform: flipVertical ? 'rotateX(180deg)' : 'rotateX(0deg)',
    transformOrigin: open
      ? '100% calc((100vh - 95px) / 2)'
      : '100% calc((100vh - 150px) / 2)',

    '@media (min-height: 400px) and (max-height: 600px)': {
      transformOrigin: open && '100% calc((100vh - (-70px)) / 2)',
    },

    '@media (min-height: 601px) and (max-height: 650px)': {
      transformOrigin: open && '100% calc((100vh - (-4%)) / 2)',
    },

    '@media (min-height: 651px) and (max-height: 700px)': {
      transformOrigin: open && '100% calc((100vh - (-3%)) / 2)',
    },

    '@media (min-height: 700px) and (max-height: 720px)': {
      transformOrigin: open && '100% calc((100vh - (3vh)) / 2)',
    },

    '@media (min-height: 720px) and (max-height: 750px)': {
      transformOrigin: open && '100% calc((100vh - (8vh)) / 2)',
    },

    '@media (min-height: 751px) and (max-height: 800px)': {
      transformOrigin: open && '100% calc((100vh - (13vh)) / 2)',
    },

    '@media (min-height: 801px) and (max-height: 850px)': {
      transformOrigin: open && '100% calc((100vh - (15vh)) / 2)',
    },

    '@media (min-height: 850px) and (max-height: 900px)': {
      transformOrigin: open && '100% calc((100vh - (22vh)) / 2)',
    },

    '@media (min-height: 900px) and (max-height: 950px)': {
      transformOrigin: open && '100% calc((100vh - (25vh)) / 2)',
    },

    '@media (min-height: 950px) and (max-height: 1000px)': {
      transformOrigin: open && '100% calc((100vh - (30vh)) / 2)',
    },

    '@media (min-height: 1200px) and (max-height: 1400px)': {
      transformOrigin: open && '100% calc((100vh - 196px) / 3)',
    },

    '@media (min-height: 1400px) and (max-height: 1800px)': {
      transformOrigin: open && '100% calc((100vh - 262px) / 4)',
    },
    maxHeight: open ? '675px' : '450px',
    minHeight: open && '675px',
  },

  '.front, .back': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: open ? '675px' : 'calc(100vh - 150px)',
    backfaceVisibility: 'hidden',
    display: open && 'flex',
    justifyContent: open && 'center',
    alignItems: open && 'center',
    maxHeight: open ? '675px' : '450px',
  },

  '.front': {
    zIndex: 2,
    transform: 'rotateX(0deg)',
  },

  '.back': {
    transform: 'rotateX(180deg)',
    zIndex: 1,
  },

  '.fade-right': {
    opacity: 0,
    transform: 'translateX(-30px)',
    animation: 'fadeRight 0.85s ease forwards',
  },

  '.fade-left': {
    opacity: 0,
    transform: 'translateX(30px)',
    animation: 'fadeLeft 0.85s ease forwards',
  },

  '@keyframes fadeRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(30px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },

  '@keyframes fadeLeft': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-30px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}));

export const CardDivWrapper = styled('div')(({ theme, open }) => ({
  background: 'white',
  width: open && '80%',
  border: '0.88px solid #00000033',
  // height: open ? '80%' : 'calc(100vh - 270px)',
  // minHeight: !open && 'calc(100vh - 250px)',
  minHeight: open ? '675px' : '450px',
  maxHeight: open ? '675px' : '450px',
  // maxHeight: open && '80%',
  // overflow: 'auto',
  paddingTop: '0px',
  boxShadow: '0px 3.52px 21.15px 0px #00000014',
  borderRadius: '15px',
  padding: open ? '0px' : `${theme.spacing(4)} 0px`,
  paddingRight: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(1)} 0px`,
    paddingTop: '30px',
    height: '250px',
  },
}));

export const FlashIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: '10px',
}));

export const SetperDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '10px',
}));

export const Typography = styled(TypographyCompo)(({ theme }) => ({
  textAlign: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

export const ButtonTerm = styled(Typography)(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  background: Palette.colors.selectedColor,
  padding: '0px 10px',
  color: Palette.colors.unselected,
  borderRadius: '50px',
  '&:hover': {
    background: Palette.colors.selectedColor,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
export const StepperWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '115px',
  height: '33px',
  padding: 'var(--Spacing-space-3, 4px) 13px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '0px 8px 8px 0px',
  background: '#624BA2',
  color: '#ffff',
  fontSize: '12px',
  fontFamily: 'Satoshi',
  [theme.breakpoints.down('sm')]: {
    width: '100px',
    padding: '0px 13px',
  },
}));

export const ButtonTermMobile = styled(Typography)(({ theme }) => ({
  color: 'rgba(98, 75, 162, 1)',
  display: 'inline-flex',
  textAlign: 'center',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '180%',
  background: 'none',
  borderRadius: '0',
  padding: '0',
  textTransform: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  [theme.breakpoints.down('sm')]: {},
}));
export const ButtonDefinitionMobile = styled(Button)(({ theme }) => ({
  display: 'none',
  color: 'var(--Pink-New-E01512, #E015A2)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    marginBottom: '15px',
  },
}));

export const ButtonDefinition = styled(Button)(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  color: Palette.colors.secondaryModified,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  fontSize: '13px',
}));
export const RightLeftButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  width: '75%',
  [theme.breakpoints.down('sm')]: {
    gap: '5px',
  },
}));
export const StyleButton = styled(Button)(
  ({ background, theme, hoverBackground }) => ({
    background: background,
    borderRadius: '9px',
    marginRight: '7px',
    marginLeft: '7px',
    height: '40px',
    width: '94px',
    color: '#000000',
    padding: '2px',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      width: '60px',
    },

    '&:hover': {
      background: `${hoverBackground} !important`,
    },
  })
);
export const ButtonTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
}));

export const ButtonDefinitionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '25px',
  marginBottom: '25px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0%',
    justifyContent: 'center',
    marginBottom: '20px',
    alignItems: 'center',
  },
}));
export const IconButtonWrapper = styled(IconButton)(({ theme, _fullview }) => ({
  display: 'flex',
  background: _fullview
    ? Palette.colors.selectedColor
    : Palette.colors.primaryModified,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
export const IconButtonWrapperMobile = styled(IconButton)(
  ({ theme, _fullview }) => ({
    marginTop: '-5px',

    '&:hover': {
      background: 'white !important',
    },
  })
);

export const DiduKnowBtn = styled(Typography)(({ theme }) => ({
  color: 'black',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginBottom: '10px',
}));

export const Wrapper = styled('div')(({ theme, open }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: '10px',
  minHeight: open ? '540px' : '360px',

  [theme.breakpoints.down('sm')]: {
    height: '47%',
    overflow: 'auto',
  },

  // '@media (min-height: 800px) and (min-width: 1200px)': {
  //   minHeight: '360px',
  // },
}));

export const FCProgressWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '-10px',
  width: '100%',
  right: '-10px',

  [theme.breakpoints.down('sm')]: {
    right: '0px',
  },
}));
