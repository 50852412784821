import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import {
  GIF,
  GIFWrapper,
  GotItButton,
  IncorrectTitle,
  ModalHeading,
  ModalWrapper,
  Finish,
} from './Congratulations.style';
import Celebration from '../../../assets/images/user/Celebration.gif';
import Congratulation from '../../../assets/images/user/Congratulation.gif';

const Congratulations = ({
  showModal = false,
  setShowModal,
  title,
  buttonTitle,
  isFlaschard,
  getMenuState,
}) => {
  const [time, setTime] = useState(5);

  const handleClose = () => {
    if (isFlaschard) {
      getMenuState(false);
    }

    setShowModal(false);
  };

  useEffect(() => {
    if (time === 0) {
      setShowModal(false);
    }
  }, [time]);

  useEffect(() => {
    if (isFlaschard && time > 0 && showModal) {
      setTimeout(() => {
        const updatedtime = time - 1;

        setTime(updatedtime);
      }, 1000);
    }
  }, [time, isFlaschard, showModal]);
  return (
    <Modal
      open={showModal}
      setOpen={handleClose}
      modalStyleOverrides={{ background: 'white' }}
    >
      <ModalWrapper isFlaschard={isFlaschard}>
        <GIFWrapper>
          <GIF src={Celebration} alt="Not Found" />
          <GIF src={Congratulation} alt="Not Found" />
          <GIF src={Celebration} alt="Not Found" />
        </GIFWrapper>

        <ModalHeading>Well done!</ModalHeading>

        <IncorrectTitle isFlaschard={isFlaschard}>{title}</IncorrectTitle>

        <GotItButton isFlaschard={isFlaschard} onClick={handleClose}>
          {buttonTitle} {isFlaschard && time}
        </GotItButton>

        {isFlaschard && <Finish onClick={handleClose}>Finish</Finish>}
      </ModalWrapper>
    </Modal>
  );
};

export default Congratulations;
