import { styled } from '@mui/material';

export const TableHeading = styled('td')(({ theme }) => ({
  padding: '7px 20px',
  borderBottom: '2px solid black',
  color: '#11263C',
  fontSize: '12px',
  fontFamily: 'Inter',
}));

export const TableData = styled('td')(({ color, border }) => ({
  background: 'rgba(98, 75, 162, 0.10)',
  color: color,
  fontSize: '12px',
  fontWeight: 500,
  fontFamily: 'Inter',
  padding: '30px',
  fontFamily: 'Inter',
  borderBottom: border ? `1px solid ${color}` : '',
}));

export const TableDataNames = styled('td')(({ theme, isDouble }) => ({
  textAlign: 'start !important',
  display: 'flex',
  padding: '30px 0',
  color: '#11263C',
  fontSize: '14px',
  fontFamily: 'Inter',
  justifyContent: 'start',
  alignItems: 'start',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

export const ModalWrapper = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '15px',
  padding: '24px',
}));

export const IncorrectTitle = styled('div')(() => ({
  color: '#656565',
  fontSize: '20px',
  fontFamily: 'HelviticaNue',
  marginBottom: '30px',
  fontWeight: 400,
  textAlign: 'center',
}));

export const GotItButton = styled('button')(() => ({
  border: 'none',
  outline: 'none',
  background: '#E015A2',
  borderRadius: '16px',
  width: '100%',
  padding: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: '20px',
  fontFamily: 'HelviticaNue',
  cursor: 'pointer',
  marginTop: '35px',

  '&:hover': {
    background: '#CB0B8B',
  },
}));

export const GIFWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const GIF = styled('img')(() => ({
  height: '120px',
  width: '120px',
}));

export const ModalHeading = styled('span')(() => ({
  textAlign: 'center',
  fontFamily: 'HelviticaNue',
  fontWeight: 700,
  fontSize: '28px',
}));
