import * as React from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AntTab, Wrapper, BoxWrapper } from "./Secondary.style";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import IframeComp from "../../../Iframe/iframe";
import { Mixpanel } from "../../../../helpers/Mixpanel";
import pallete from "../../../../config/palette";

import { formatText } from "../../List/Utils";
import { useDispatch, useSelector } from "react-redux";
import { learningResourcesHighlightActions } from "../../../../redux/reducers/learningResourcesHighlight";
import constants from "../../../../config/constants";

const Secondary = ({ tabs, tabIndicatorColor, selectedtabcolor, location }) => {
  const [tabValue, setValue] = React.useState(0);
  const { name, tab, id, value } = useParams();
  const isHighlightingActive = useSelector(
    (state) => state.learningResources.isHighlighting
  );
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);

    navigate(`/dashboard/${name}/${id}/${value}/${location}/${newValue}`);
  };

  const mixpanelTracking = (tab, location, name, id) => {
    Mixpanel.track(`Accessed ${constants.tabs[tab]}`, {
      courseName: name,
      courseID: id,
      selectedTab: constants.tabs[tab],
      library: location,
    });
  };

  useEffect(() => {
    if (tab) setValue(parseInt(tab));
    if ((tab, location, name, id)) mixpanelTracking(tab, location, name, id);
  }, [tab, location, name, id]);

  useEffect(() => {
    if (isHighlightingActive && dispatch) {
      dispatch(learningResourcesHighlightActions.toggleHighlightState());
    }
  }, [isHighlightingActive, dispatch]);

  const [showBot, setShowBot] = React.useState(false);

  const HandleBotClick = () => {
    setShowBot(!showBot);

    Mixpanel.track("Chatbot (Widget) Clicked", {
      page: "Physics course",
    });
  };

  return (
    <>
      <Wrapper>
        <TabContext value={tabValue}>
          <BoxWrapper sx={{ display: "block", marginBottom: "-10px" }}>
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              left
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ style: { background: tabIndicatorColor } }}
            >
              {tabs.map((tab, index) => (
                <AntTab
                  icon={
                    <tab.icon
                      isMobile={true}
                      color={
                        tabValue === tab.value
                          ? pallete.colors.secondaryModified
                          : "#11263CB2"
                      }
                    />
                  }
                  key={index}
                  label={tab.label}
                  value={tab.value}
                  selectedtabcolor={pallete.colors.secondaryModified}
                  active={tabValue === tab.value}
                />
              ))}
            </TabList>
          </BoxWrapper>
          {tabs.map((tab, index) => (
            <TabPanel value={tab.value} key={index}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      </Wrapper>

      <IframeComp
        showBot={showBot}
        HandleBotClick={HandleBotClick}
        url={"https://www.chatbase.co/chatbot-iframe/-_xqd7Unl_jkApvtNgaLJ"}
      />
    </>
  );
};

export default Secondary;
Secondary.propTypes = {
  tabIndicatorColor: PropTypes.string,
  selectedtabcolor: PropTypes.string,
};
Secondary.defaultProps = {
  tabIndicatorColor: "#fff",
  selectedtabcolor: "#624BA2",
};
