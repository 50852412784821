import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ReactDOM from "react-dom";
import { MoveToLibraryButtonStyled } from "./NotesContent";
import { Wrapper } from "./RenderNote.style";
import { useTheme } from "@emotion/react";
import TextSelectionComp from "../../../../../../UI/TextSelection/TextSelectionComp";
import MoveLibrarySvg from "../../../../LeftSideBar/Icons/MoveLibrarySvg";
import { useSelector, Provider } from "react-redux";
import SearchableText from "./../../../../../../UI/SearchableText/SearchableText";
import TextComp from "./TextComp";
import { IconButtonWrapper } from "./TextComp.style";
import { store } from "../../../../../../../redux/store";
import api from "../../../../../../../Services";
import Loader from "../../../../../../UI/Loader/Loader";
import Alert from "../../../../../../UI/Alert/Alert";
import MulitipleCommentsSVG from "./icons/MulitipleCommentsSVG";
import SingeCommentSVG from "./icons/SingeCommentSVG";
import {
  closeALLComments,
  handleCloseBox,
} from "../../../../../../../helpers/textComp";
import { Mixpanel } from "../../../../../../../helpers/Mixpanel";
import { formatText } from "../../../../../../UI/List/Utils";
import Modal from "../../../../../../UI/Modal/Modal";
import GeneralModal from "../../../../../../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/Models/GeneralModal/GeneralModal.jsx";
import MobileModal from "../../../../../../mobileModal/mobileModal.jsx";
import constants from "../../../../../../../config/constants.js";
const RenderNote = (props) => {
  const {
    addToLibraryHandler,
    tagId,
    noteId,
    type,
    name,
    snackId,
    chapterId,
    expanded,
    activeIndex,
    setActiveIndex,
    index,
  } = props;
  const [showIcons, setShowIcons] = React.useState(false);
  const [highlightContent, setHighlightContent] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [commentsOnNotes, setCommentsOnNotes] = React.useState([]);
  const [noteError, setNoteError] = React.useState(false);
  const theme = useTheme();
  const isHighlightingActive = useSelector((state) => state.myLibraryHighlight);
  const { searchedTextValue } = isHighlightingActive;
  const [currentData, setCurrentData] = React.useState(null);
  const [allowCopy, setAllowCopy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [activeComments, setActiveComments] = useState("");

  const updateHighlightedNotesApi = async () => {
    try {
      let payload = {
        id: highlightContent.id,
        snackId,
        chapterId,
        content: document.getElementById(noteId).innerHTML,
        noteId,
      };
      const result = await api.updateHighlightedNote(payload);
      const { error } = result;
      if (error) {
        throw new Error(result.error);
      } else {
        setHighlightContent(payload);
        let _currentData = { ...currentData };
        _currentData.highlights = result.data[1];
        setNoteError(false);
        return _currentData;
      }
    } catch (error) {
      setNoteError(true);
      setIsLoading(false);
    }
  };

  const addHighlightedNotesApi = async () => {
    try {
      let payload = {
        snackId,
        chapterId,
        content: document.getElementById(noteId).innerHTML,
        noteId,
      };
      const result = await api.addHighlightedNotes(payload);
      setHighlightContent(payload);
      let _currentData = { ...currentData };
      _currentData.highlights = [payload];
      setNoteError(false);
      const { error } = result;
      if (error) {
        throw new Error(result.error);
      } else {
        setHighlightContent(result.data);
        let _currentData = { ...currentData };
        _currentData.highlights = [result.data];
        setNoteError(false);
        return _currentData;
      }
    } catch (error) {
      setNoteError(true);
      setIsLoading(false);
    }
  };

  const addComment = async (payload) => {
    try {
      const result = await api.addComment(payload);
      const { error } = result;
      if (error) {
        throw new Error(result.error);
      } else {
        setNoteError(false);
        return result.data;
      }
    } catch (error) {
      setNoteError(true);
      setIsLoading(false);
    }
  };

  function converter(data) {
    if (data?.highlights?.length) {
      return JSON.stringify({ html: data.highlights[0].content });
    }
    return data?.body;
  }
  const fetchContent = async () => {
    if (snackId) {
      await api
        .noteBySnackandTag({ snackId, tagId })
        .then((resp) => {
          setCurrentData(resp.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      await api
        .noteByChapterandTag({ chapterId, tagId })
        .then((resp) => {
          setCurrentData(resp.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };
  React.useEffect(() => {
    if (parseInt(expanded) === parseInt(tagId) && !currentData) {
      setIsLoading(true);
      fetchContent();
    }
  }, [expanded, tagId]);

  React.useEffect(() => {
    if (expanded) {
      setIsLoading(true);
      fetchContent();
    }
  }, [tagId]);

  React.useEffect(() => {
    if (currentData) {
      if (currentData) {
        setCommentsOnNotes(currentData.comments);
      }
      if (currentData?.highlights?.length) {
        setHighlightContent(currentData?.highlights[0]);
      }
    }
  }, [currentData]);

  const sideEffectsOfHighlighting = async (isComment) => {
    if (!isComment) {
      Mixpanel.track("Highlight Created", {
        library: "octilearnlibrary",
        courseName: props.courseName,
        unitName: props.unitName,
        snackName: props.snackName,
        tab: constants.tabs[props.tab],
      });
    }
    commentsOnNotes.length > 0 &&
      commentsOnNotes.forEach((ele) => {
        let element = document.getElementById(ele?.commentId);
        if (element) {
          ReactDOM.unmountComponentAtNode(element);
        }
      });

    if (highlightContent) {
      let _currentData = await updateHighlightedNotesApi();
      if (!isComment) {
        setCurrentData(_currentData);
        return;
      }
      return _currentData;
    } else {
      let _currentData = await addHighlightedNotesApi();
      if (!isComment) {
        setCurrentData(_currentData);
        return;
      }
      return _currentData;
    }
  };

  const sideEffectsOfCommenting = async (data) => {
    Mixpanel.track("Comment Added", {
      library: "octilearnlibrary",
      courseName: props.courseName,
      unitName: props.unitName,
      snackName: props.snackName,
      tab: constants.tabs[props.tab],
    });
    commentsOnNotes.length > 0 &&
      commentsOnNotes.forEach((ele) => {
        if (document.getElementById(ele.commentId)) {
          ReactDOM.unmountComponentAtNode(
            document.getElementById(ele.commentId)
          );
        }
      });
    const payload = {
      snackId,
      chapterId,
      content: data.comment,
      noteId,
      commentId: data.commentId,
    };

    let cdata = await sideEffectsOfHighlighting(true);
    let newcomment = await addComment(payload);
    cdata.comments = [...cdata.comments, newcomment];

    setCurrentData(cdata);
  };

  const childRef = React.useRef();

  function handleClick(id) {
    const commentObj = commentsOnNotes?.find((ele) => ele.commentId === id);
    commentsOnNotes?.map((comment) => {
      if (comment.commentId != id) {
        const found = document.getElementById(comment.commentId);

        const child = found ? found?.firstChild : null;

        if (child) {
          ReactDOM.unmountComponentAtNode(
            document.getElementById(comment.commentId)
          );
        }
      }
    });
    const child = document.getElementById(id).firstChild;
    if (child) {
      ReactDOM.unmountComponentAtNode(document.getElementById(id));
    } else {
      ReactDOM.render(
        <Provider store={store}>
          <TextComp
            value={commentObj.content}
            commentId={id}
            noteId={commentObj.noteId}
            deleteComment={deleteComment}
            handleActiveComment={() => {
              setActiveComments("");
            }}
            close={() => {
              handleCloseBox(id);
            }}
          />
        </Provider>,
        document.getElementById(id)
      );
    }
  }

  const deleteComment = async (commentId) => {
    try {
      const result = await api.deleteComment(commentId, "comment");

      const { error } = result;

      if (error) {
        throw new Error(result.error);
      } else {
        commentsOnNotes.length > 0 &&
          commentsOnNotes.forEach((ele) => {
            let element = document.getElementById(ele?.commentId);
            if (element) {
              ReactDOM.unmountComponentAtNode(element);
            }
          });
        let _currentData = await updateHighlightedNotesApi();
        let temp = currentData.comments.filter(
          (ele) => ele.commentId !== commentId
        );
        _currentData.comments = temp;

        setCurrentData(_currentData);

        setNoteError(false);
      }
    } catch (error) {
      console.error(error);
      setNoteError(true);
    }
  };

  useEffect(() => {
    if (allowCopy) {
      addToLibraryHandler(
        { body: currentData, tagId, noteId, snackId, chapterId, type },
        "specific-tag"
      );
      Mixpanel.track(
        `Saved ${formatText(name)} ${formatText(type)} in My Library`,
        {
          page: "Notes",
        }
      );
      enqueueSnackbar("Content successfully copied to My Library.", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setAllowCopy(false);
    }
  }, [allowCopy]);

  return (
    <Wrapper>
      {/* buffer component */}
      {isLoading ? (
        <Loader />
      ) : noteError ? (
        <Alert message={"Error Occured !"} severity={"error"} />
      ) : (
        <TextSelectionComp
          sideEffectsOfCommenting={sideEffectsOfCommenting}
          sideEffectsOfHighlighting={sideEffectsOfHighlighting}
          isHighlightingActive={true} //Hides Highlight and Comments from Learning Resource>>Notes
          index={index}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {currentData && (
              <SearchableText
                htmlData={converter(currentData)}
                ref={childRef}
                noteId={noteId}
                searchedTextValue={searchedTextValue}
              />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {commentsOnNotes?.length === 0 ? null : (
                <IconButtonWrapper
                  style={{
                    marginBottom: "0px",
                    padding: "10px 0px 10px 0px",
                  }}
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    if (showIcons) {
                      closeALLComments(commentsOnNotes);
                      setActiveComments("");
                    }

                    setShowIcons(!showIcons);
                  }}
                >
                  <MulitipleCommentsSVG active={showIcons} />
                </IconButtonWrapper>
              )}

              {showIcons &&
                commentsOnNotes.map((ele) => {
                  return (
                    <IconButtonWrapper
                      style={{
                        padding: "2px 0px 0px 0px",
                      }}
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        if (activeComments === ele?.commentId) {
                          setActiveComments("");
                        } else {
                          setActiveComments(ele?.commentId);
                        }
                        handleClick(ele.commentId);
                      }}
                    >
                      <SingeCommentSVG
                        active={activeComments === ele?.commentId}
                      />
                    </IconButtonWrapper>
                  );
                })}
            </div>
          </div>
        </TextSelectionComp>
      )}
    </Wrapper>
  );
};

export default RenderNote;
