import { styled } from '@mui/system';

export const Wrapper = styled('div')(({ theme, drawerwidth }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  width: drawerwidth,
  minHeight: theme.spacing(62.5),
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  background: '#251038',
  zIndex: 2000,
  color: '#fff',
}));

export const DashboardIconWrapper = styled('div')(({ theme, isMobile }) => ({
  cursor: 'pointer',
  marginBottom: theme.spacing(4.0025),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    display: isMobile ? 'block' : 'none',
  },
}));

export const Image = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(5.38875),
  width: '60%',
}));

export const HelpImage = styled('div')(({ theme }) => ({
  color: 'white',
  opacity: '0.3',
  '&:hover': {
    opacity: 1,
  },
}));

export const SideBarOutterWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SideBarMenutitle = styled('span')(({ hovered }) => ({
  fontSize: '10px',
  fontWeight: 500,
  color: hovered ? 'white' : '#ADB4C5',
  fontFamily: 'Satoshi',
  marginBottom: '-5px',
}));

export const SideBarBottomWrapper = styled('div')(({ theme }) => ({
  width: '80%',
  position: 'absolute',
  borderTop: '1px solid #B2B2B2',
  bottom: '45px',
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));
