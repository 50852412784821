import { styled } from '@mui/system';
import Button from '../../../../../UI/Button/ButtonComp';
import palette from './../../../../../../config/palette';
import GridComp from './../../../../../UI/Grid/Grid.jsx';
import { Stack, Typography } from '@mui/material';

export const FlashCardHeaderWrapper = styled(GridComp)(
  ({ theme, baseView }) => ({
    background: '#ffff',
    display: baseView ? 'inline-flex' : 'none',
    width: '100%',
    padding: '0px',
    height: '150px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px',
    marginLeft: '0px',
    marginTop: theme.spacing(3),
    borderRadius: ' 10px',
    boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '10px 0px',
      height: baseView ? '115px' : '80px',
      margin: ' 20px 0px',
    },
  })
);

export const FlashCardTagsButton = styled(Button)(({ theme, isActive }) => ({
  textTransform: 'capitalize',
  color: isActive ? 'white' : '#58458E',
  backgroundColor: isActive ? '#7C63C8' : '#F1F2FC',
  borderRadius: '50px',
  marginRight: '0.4rem',
  marginLeft: '0.4rem',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: '400',
  border: '1px solid #7C63C8',

  '&:hover': {
    color: '#FFFF',
    backgroundColor: '#7C63C8',
  },

  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(1),
  },
  minWidth: 'max-content',

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    margin: '3px',
    padding: '3px 8px',
  },
}));

export const FlashCardTagsButtonMobile = styled(Button)(
  ({ theme, isActive }) => ({
    display: 'inline-flex',
    padding: '3px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '100px',
    background: '#624BA2',
    boxShadow: ' 0px 4px 4px 0px #48357C inset',
    color: 'var(--white, #FFF)',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: ' 700',
    lineHeight: '180%',
  })
);

export const FlashCardTagStack = styled(Stack)(({ theme, isActive }) => ({
  alignItems: 'center',
  padding: theme.spacing(1),
  width: '100%',
  overflowX: 'auto',
  marginLeft: '10px',
}));

export const SelectedHeading = styled(Typography)(({ theme }) => ({
  display: 'none',
  color: 'rgba(17, 38, 60, 0.70)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));
export const TotalFlashCard = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: 'var(--main-text, #251038)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
}));
