import React from 'react';

function NewNextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      style={{
        minWidth: '20px',
      }}
    >
      <path
        d="M7.69834 13.8478C7.38918 14.1569 7.38918 14.6582 7.69834 14.9673C8.00751 15.2765 8.50876 15.2765 8.81793 14.9673L13.5679 10.2173C13.8676 9.91764 13.8781 9.43505 13.5917 9.1226L9.23755 4.3726C8.94211 4.0503 8.44132 4.02853 8.11902 4.32397C7.79672 4.61941 7.77495 5.1202 8.07039 5.4425L11.9124 9.63374L7.69834 13.8478Z"
        fill="#624BA2"
      />
    </svg>
  );
}

export default NewNextIcon;
