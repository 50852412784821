import React from 'react';
import Divider from '@mui/material/Divider';
import { StackWrapper, ColDiv, FlashCardInfoTypo } from './FlashCardView.style';
import Pallete from './../../../config/palette.js';
import TypographyCompo from './../Typography/TypographyCompo';
import StackCompo from './../Stack/Stack';
import { Box } from '@mui/material';

// remaining,notLearned, knownWell, notSure
const StackNumbers = ({
  remaining,
  notLearned,
  knownWell,
  notSure,
  handleFilter,
  activeTag,
  open,
}) => {
  return (
    <StackWrapper open={open}>
      <StackCompo
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'space-between',
              backgroundColor: '#B0B0B0 !important',
            }}
          />
        }
        spacing={{ xs: 3, sm: 1 }}
      >
        <ColDiv
          onClick={() => {
            handleFilter('remaining');
          }}
        >
          <FlashCardInfoTypo isValue={true} color={'#624BA2'}>
            {remaining}
          </FlashCardInfoTypo>
          <FlashCardInfoTypo active={activeTag === 'REMAINING'}>
            Remaining
          </FlashCardInfoTypo>
        </ColDiv>

        <ColDiv
          onClick={() => {
            handleFilter('NO');
          }}
        >
          <FlashCardInfoTypo
            isValue={true}
            color={'#F00000'}
            active={activeTag === 'NOT LEARNED'}
          >
            {notLearned}
          </FlashCardInfoTypo>
          <FlashCardInfoTypo active={activeTag === 'NOT LEARNED'}>
            Not Learnt
          </FlashCardInfoTypo>
        </ColDiv>

        <ColDiv
          onClick={() => {
            handleFilter('MAYBE');
          }}
        >
          <FlashCardInfoTypo isValue={true} color={'#FF9707'}>
            {notSure}
          </FlashCardInfoTypo>
          <FlashCardInfoTypo active={activeTag === 'NOT SURE'}>
            Not Sure
          </FlashCardInfoTypo>
        </ColDiv>

        <ColDiv
          onClick={() => {
            handleFilter('YES');
          }}
        >
          <FlashCardInfoTypo isValue={true} color={'#81D0D4'}>
            {knownWell}
          </FlashCardInfoTypo>
          <FlashCardInfoTypo active={activeTag === 'KNOWN WELL'}>
            Known Well
          </FlashCardInfoTypo>
        </ColDiv>
      </StackCompo>
    </StackWrapper>
  );
};

export default StackNumbers;
