import { styled } from '@mui/material';

export const ModalWrapper = styled('span')(({ isFlaschard }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: isFlaschard ? '20px' : '15px',
  padding: '24px',
}));

export const IncorrectTitle = styled('div')(({ isFlaschard }) => ({
  color: '#656565',
  fontSize: isFlaschard ? '16px' : '20px',
  fontFamily: isFlaschard ? 'Inter' : 'HelviticaNue',
  marginBottom: isFlaschard ? '0px' : '30px',
  fontWeight: 400,
  textAlign: 'center',
}));

export const Finish = styled('div')(() => ({
  color: '#454545',
  fontSize: '20px',
  fontFamily: 'Inter',
  fontWeight: 500,
  textAlign: 'center',
  cursor: 'pointer',
}));

export const GotItButton = styled('button')(({ isFlaschard }) => ({
  border: 'none',
  outline: 'none',
  background: '#E015A2',
  borderRadius: '16px',
  width: '100%',
  padding: isFlaschard ? '12px 24px' : '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: '20px',
  fontFamily: isFlaschard ? 'Inter' : 'HelviticaNue',
  cursor: 'pointer',
  marginTop: isFlaschard ? '0px' : '35px',
  maxWidth: isFlaschard && '258px',

  '&:hover': {
    background: '#CB0B8B',
  },
}));

export const GIFWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const GIF = styled('img')(() => ({
  height: '120px',
  width: '120px',
}));

export const ModalHeading = styled('span')(() => ({
  textAlign: 'center',
  fontFamily: 'HelviticaNue',
  fontWeight: 700,
  fontSize: '28px',
}));
