import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import QuestionCard from '../components/QuestionCard';
import { CircularProgress, LinearProgress, Pagination } from '@mui/material';
import capitalize from '../../../../../../../../helpers/capitalize';
import GRAPHICS from '../../../../../../../../assets/images/assessment';

import {
  AssessmentCompletionModal,
  AssessmentCompletionWrapper,
  AssessmentLoaderWrapper,
  AssessmentModalHeading,
  BtnSection,
  ClickAwayWrapper,
  PagniationInfo,
  PercentageSpan,
  PinkText,
  ResultBackWrapper,
  ResultCorrectScores,
  ResultIncorrectScores,
  ResultProgrssWrapper,
  ResultScoresContent,
  ResultScoresContentWrapper,
  ResultScoresHeading,
  ResultScoresWrapper,
  ReviewCardWrapper,
  ReviewHeading,
  ReviewSubmitButton,
  ReviewSubmitSection,
  ReviewText,
  SubmitSection,
} from '../Assessment.style';

const ResultScreen = ({
  allQuestions,
  userAttemptedQuestins,
  course,
  newSelection,
  isAnalytics = false,
  analyticsQuestions,
  correctStatus,
  setIsCorrect,
  analyticsAllQuestions,
}) => {
  const { name, id, value } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const questionsPerPage = 10; // Number of questions per page

  const reviewQuestions = allQuestions?.filter((item) => {
    return userAttemptedQuestins?.some((q) => item?.id === q?.questionId);
  });

  const userCorrectAnswers = userAttemptedQuestins?.filter(
    (item) => item?.isCorrect
  );

  const percentage =
    userCorrectAnswers?.length > 0
      ? Math.floor(
          (userCorrectAnswers?.length * 100) / userAttemptedQuestins?.length
        )
      : 0;

  const userWrongQuestions =
    userAttemptedQuestins?.length - userCorrectAnswers?.length;

  useEffect(() => {
    setFilteredQuestions(reviewQuestions);
    setTimeout(() => setLoading(false), 3000);
  }, []);

  const handleQuestion = (type) => {
    let filtered;

    if (type === 'correct') {
      filtered = reviewQuestions?.filter((item) => {
        return userAttemptedQuestins?.some(
          (q) => item?.id === q?.questionId && q?.isCorrect
        );
      });
    } else if (type === 'incorrect') {
      filtered = reviewQuestions?.filter((item) => {
        return userAttemptedQuestins?.some(
          (q) => item?.id === q?.questionId && !q?.isCorrect
        );
      });
    } else {
      filtered = reviewQuestions;
    }

    setFilteredQuestions(filtered);
    setCurrentPage(1);
  };

  const totalAnalyticsQuestions = Math.floor(
    analyticsAllQuestions?.Correct?.length +
      analyticsAllQuestions?.Incorrect?.length
  );

  const analyticsQuestionsPercentage = Math.floor(
    (analyticsAllQuestions?.Correct?.length * 100) / totalAnalyticsQuestions
  );

  const totalQuestions =
    isAnalytics && correctStatus
      ? analyticsAllQuestions?.Correct?.length
      : isAnalytics && !correctStatus
      ? analyticsAllQuestions?.Incorrect?.length
      : filteredQuestions?.length;

  const totalPages = Math.ceil(totalQuestions / questionsPerPage);

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const currentQuestions = filteredQuestions?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const analyticsCurrentQuestions = correctStatus
    ? analyticsAllQuestions?.Correct?.slice(
        indexOfFirstQuestion,
        indexOfLastQuestion
      )
    : analyticsAllQuestions?.Incorrect?.slice(
        indexOfFirstQuestion,
        indexOfLastQuestion
      );

  return (
    <div>
      {loading && !isAnalytics && (
        <AssessmentCompletionWrapper>
          <AssessmentCompletionModal>
            <AssessmentLoaderWrapper>
              <LinearProgress
                sx={{
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#E015A2',
                  },
                }}
              />
            </AssessmentLoaderWrapper>

            <img src={GRAPHICS.Submitted} alt="Submit" />

            <AssessmentModalHeading>
              Submitting Assessment!
            </AssessmentModalHeading>
          </AssessmentCompletionModal>
        </AssessmentCompletionWrapper>
      )}

      {!isAnalytics && (
        <ReviewSubmitSection>
          <SubmitSection>
            <BtnSection>
              <ClickAwayWrapper>
                <ReviewHeading>
                  <PinkText>Submission Result</PinkText>
                </ReviewHeading>

                <ReviewText>
                  {capitalize(newSelection?.type)}: {newSelection?.label}
                </ReviewText>
              </ClickAwayWrapper>

              <Link to={`/dashboard/${name}/${id}/${value}/octilearnlibrary/4`}>
                <ReviewSubmitButton>
                  View Performance Analytics
                </ReviewSubmitButton>
              </Link>
            </BtnSection>
            <div
              style={{
                width: '60%',
                maxHeight: '120px',
              }}
            >
              <img
                src={course?.img}
                alt="img"
                style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '120px',
                  objectFit: 'cover',
                }}
              />
            </div>
          </SubmitSection>
        </ReviewSubmitSection>
      )}

      <ResultScoresWrapper>
        <ResultCorrectScores
          onClick={() => {
            handleQuestion('correct');
            if (isAnalytics) {
              setIsCorrect('Correct');
            }
          }}
        >
          <ResultScoresContentWrapper>
            <ResultScoresHeading>Correct Answers</ResultScoresHeading>

            {isAnalytics ? (
              <>
                <ResultScoresContent>
                  You answered {analyticsAllQuestions?.Correct?.length} out of{' '}
                  {totalAnalyticsQuestions} questions correctly.
                </ResultScoresContent>
              </>
            ) : (
              <ResultScoresContent>
                You answered {userCorrectAnswers?.length} out of{' '}
                {userAttemptedQuestins?.length} questions correctly.
              </ResultScoresContent>
            )}
          </ResultScoresContentWrapper>

          <ResultProgrssWrapper>
            <div style={{ position: 'relative', paddingRight: '10px' }}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9.5" cy="9.5" r="9.5" fill="#5FA4A8" />
              </svg>
              {isAnalytics ? (
                <>
                  <PercentageSpan
                    customLeft={analyticsQuestionsPercentage == 100}
                  >
                    {analyticsQuestionsPercentage}%
                  </PercentageSpan>
                </>
              ) : (
                <PercentageSpan customLeft={percentage == 100}>
                  {percentage < 1 ? '0' + percentage : percentage}%
                </PercentageSpan>
              )}

              <CircularProgress
                style={{
                  color: '#2A7A7E',
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  left: -6,
                  top: -6,
                  border: '5px',
                }}
                thickness={6}
                value={isAnalytics ? analyticsQuestionsPercentage : percentage}
                variant="determinate"
              />
            </div>
          </ResultProgrssWrapper>
        </ResultCorrectScores>

        <ResultIncorrectScores
          onClick={() => {
            handleQuestion('incorrect');
            if (isAnalytics) {
              setIsCorrect('Incorrect');
            }
          }}
        >
          <ResultScoresContentWrapper>
            <ResultScoresHeading>Incorrect Answers</ResultScoresHeading>

            {isAnalytics ? (
              <ResultScoresContent>
                You answered {analyticsAllQuestions?.Incorrect?.length} out of{' '}
                {analyticsAllQuestions?.Correct?.length +
                  analyticsAllQuestions?.Incorrect?.length}{' '}
                questions correctly.
              </ResultScoresContent>
            ) : (
              <ResultScoresContent>
                You answered {userWrongQuestions} out of{' '}
                {userAttemptedQuestins?.length} questions incorrectly.
              </ResultScoresContent>
            )}
          </ResultScoresContentWrapper>

          <ResultProgrssWrapper>
            <div style={{ position: 'relative', paddingRight: '10px' }}>
              {isAnalytics ? (
                <PercentageSpan
                  customLeft={100 - analyticsQuestionsPercentage == 100}
                >
                  {100 - analyticsQuestionsPercentage < 1
                    ? '0' + 100 - analyticsQuestionsPercentage
                    : 100 - analyticsQuestionsPercentage}
                  %{' '}
                </PercentageSpan>
              ) : (
                <PercentageSpan customLeft={100 - percentage == 100}>
                  {100 - percentage < 1
                    ? '0' + 100 - percentage
                    : 100 - percentage}
                  %{' '}
                </PercentageSpan>
              )}
              <svg
                width="40"
                height="40"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9.5" cy="9.5" r="9.5" fill="#E563BD" />
              </svg>

              <CircularProgress
                style={{
                  color: '#AE0C7C',
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  left: -6,
                  top: -6,
                  border: '5px',
                }}
                thickness={6}
                value={
                  isAnalytics
                    ? 100 - analyticsQuestionsPercentage
                    : 100 - percentage
                }
                variant="determinate"
              />
            </div>
          </ResultProgrssWrapper>
        </ResultIncorrectScores>
      </ResultScoresWrapper>

      <ReviewCardWrapper>
        {isAnalytics && analyticsQuestions?.length > 0 ? (
          <>
            {analyticsCurrentQuestions?.map((question, index) => {
              const globalIndex = indexOfFirstQuestion + index;

              return (
                <QuestionCard
                  key={question.id}
                  item={question}
                  resultCard={true}
                  index={globalIndex}
                  isCorrect={question?.userAnswer === question.correctAnswer}
                  analytics={correctStatus}
                  totalQuestions={analyticsQuestions?.length}
                  correctAnswer={question?.correctAnswer}
                  selectedOption={question?.userAnswer}
                />
              );
            })}
          </>
        ) : (
          <>
            {currentQuestions &&
              currentQuestions?.map((item, index) => {
                const userAnswer = userAttemptedQuestins?.find((question) => {
                  return question?.questionId === item?.id;
                });

                const globalIndex = indexOfFirstQuestion + index;

                return (
                  <QuestionCard
                    key={item.id}
                    item={item}
                    resultCard={true}
                    index={globalIndex}
                    totalQuestions={filteredQuestions?.length}
                    userAttemptedQuestins={userAttemptedQuestins}
                    isCorrect={userAnswer?.isCorrect}
                    selectedOption={userAnswer?.selectedOption}
                  />
                );
              })}
          </>
        )}
      </ReviewCardWrapper>

      <ResultBackWrapper>
        <PagniationInfo>
          Showing {indexOfFirstQuestion + 1} -
          {totalQuestions > indexOfLastQuestion
            ? indexOfLastQuestion
            : totalQuestions}{' '}
          out of {totalQuestions}
        </PagniationInfo>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          showFirstButton
          showLastButton
        />
      </ResultBackWrapper>
    </div>
  );
};

export default ResultScreen;
